import styled from 'styled-components';
import Text from '../../components/Text';

import { mq, space } from '../../stylesheets';

export const StyledBiography = styled.div`
  position: relative;
  padding-bottom: ${space('xl')};

  & > * + * {
    margin-top: ${space('xxl')};
  }
`;

export const ContentWrapper = styled.div`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${space('m')};

  @media print {
    break-before: page;
  }

  & > * + * {
    margin-top: ${space('xxl')};
  }
`;

export const BiographyText = styled(Text)`
  a {
    text-decoration: underline;
  }
`;

export const LocalizationHint = styled.div`
  display: flex;

  @media print {
    display: none;
  }

  svg {
    margin-right: ${space('xs')};
    flex-shrink: 0;

    ${mq('m')} {
      margin-right: ${space('s')};
      margin-top: 2px;
    }
  }
`;
