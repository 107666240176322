import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

import { space } from '../../../stylesheets';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';

type SourcesProps = {
  onlineSources?: string[] | null;
  sources?: string[] | null;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div + div {
    margin-top: ${space('l')};
  }

  h3 {
    margin-bottom: ${space('m')};
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 0 ${space('xxs')};
    list-style-type: none;
    padding: 0;
    display: flex;

    & > span {
      margin-right: ${space('xs')};
    }

    a {
      display: flex;

      svg {
        flex-shrink: 0;
        margin-top: ${space('xxs')};
        margin-right: ${space('xs')};
      }

      &:hover,
      &:focus {
        span {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 3px;
        }
      }
    }
  }
`;

const isUrl = (source: string) =>
  source.match(
    /https?:\/\/(www.)?([-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6})\b([-a-zA-Z0-9()@:%_+.~#?&\/\/=]*)/gm
  );

const Sources = ({ onlineSources, sources }: SourcesProps) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <div>
        <Text variant="headline-4" as="h3" id="sources">
          {t('biography.sources')}
        </Text>

        {sources && sources.length > 0 ? (
          <ul>
            {(sources || []).map(i => (
              <li key={i}>
                <Text as="span" variant="copy-small">
                  –
                </Text>
                <Text variant="copy-small">{i}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text as="span" variant="copy-small">
            –
          </Text>
        )}
      </div>

      <div>
        <Text variant="headline-4" as="h3" id="sources">
          {t('biography.onlineSources')}
        </Text>

        {onlineSources && onlineSources.length > 0 ? (
          <ul>
            {(onlineSources || []).map(i => (
              <li key={i}>
                {isUrl(i) ? (
                  <a href={i} target="_blank" rel="noopener noreferrer">
                    <Icon icon="External" size="supertiny" />
                    <Text as="span" variant="copy-small">
                      {i}
                    </Text>
                  </a>
                ) : (
                  <Text as="span" variant="copy-small">
                    – {i}
                  </Text>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <Text as="span" variant="copy-small">
            –
          </Text>
        )}
      </div>
    </Wrapper>
  );
};

export default Sources;
