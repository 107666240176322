import { useMemo } from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

import { Biography } from '../../../types/api';

import Text from '../../../components/Text';

import { color, mq, space } from '../../../stylesheets';
import { getRandomItemFromArray } from '../../../lib/helper';

const StyledBadge = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 ${space('m')};

  ${mq('l')} {
    transform: translateX(-50px);
  }

  @media print {
    padding-top: ${space('xxl')};
  }
`;

const ImageWrapper = styled(motion.div as any)`
  width: 200px;
  height: 200px;
  margin-bottom: -40px;
  align-self: center;

  @media print {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  ${mq('l')} {
    width: 300px;
    height: 300px;
    margin-bottom: -60px;
  }
`;

const BadgeWrapper = styled.div`
  background: ${color('neutral', 10)};
  text-align: center;
  padding: ${space('m')};
  border: 2px solid ${color('primary')};
  margin-bottom: 20px;
  position: relative;
  color: ${color('primary')};
  width: 100%;

  ${mq('l')} {
    margin-bottom: 50px;
    padding: ${space('l')};
  }

  &:after {
    height: calc(100% + 4.4px);
    top: -2px;
    width: 20px;
    right: -22px;
    transform: skewY(45deg);
    transform-origin: top left;
    display: none;

    ${mq('l')} {
      width: 50px;
      display: block;
      right: -52px;
    }
  }

  h1 {
    margin-bottom: ${space('m')};
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    & > * + * {
      margin-left: ${space('m')};
    }
  }
`;

type BadgeProps = Pick<
  Biography,
  | 'name'
  | 'birthName'
  | 'birthDate'
  | 'birthDatePartial'
  | 'deathDate'
  | 'deathDatePartial'
  | 'informationDensity'
>;

const Badge = ({
  name,
  birthName,
  birthDate,
  birthDatePartial,
  deathDate,
  deathDatePartial,
  informationDensity
}: BadgeProps) => {
  const { t } = useTranslation('common');

  const stoneImage = useMemo(() => {
    let index = 1;

    if (informationDensity === 0) index = 1;
    if (informationDensity === 0.25) index = 2;
    if (informationDensity === 0.5) index = 3;
    if (informationDensity === 0.75) index = 4;
    if (informationDensity === 1) index = 4;

    return `/stones/stone-0${index}-0${getRandomItemFromArray([1, 2])}.png`;
  }, [informationDensity]);

  const getBirthDate = () => {
    if (birthDate) return dayjs(birthDate).format(t('dateFormat.short'));
    if (birthDatePartial) return birthDatePartial;
    return t('unknown');
  };

  const getDeathDate = () => {
    if (deathDate) return dayjs(deathDate).format(t('dateFormat.short'));
    if (deathDatePartial) return deathDatePartial;
    return t('unknown');
  };

  return (
    <StyledBadge>
      <ImageWrapper
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.6, ease: 'easeInOut' }}
      >
        <Image key={informationDensity} src={stoneImage} alt="" width={500} height={500} />
      </ImageWrapper>

      <BadgeWrapper>
        <Text as="h1" variant="headline-1">
          {name}
        </Text>

        <Text
          variant="copy-large"
          dangerouslySetInnerHTML={{
            __html: birthName ? `${t('biography.born')} ${birthName}` : '&nbsp;'
          }}
        />

        <div>
          <Text variant="copy-large">{getBirthDate()}</Text>
          <Text variant="copy-large" as="span">
            –
          </Text>
          <Text variant="copy-large">{getDeathDate()}</Text>
        </div>
      </BadgeWrapper>
    </StyledBadge>
  );
};

export default Badge;
