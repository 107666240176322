import Link from 'next/link';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';

import { Relative } from '../../../types/api';

import Text from '../../../components/Text';
import Stack from '../../../components/Stack';
import Icon from '../../../components/Icon';

import { color, space } from '../../../stylesheets';

type RelativesProps = {
  items: Relative[];
  onRouteChange?: (slug: string) => void;
};

const RelativesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: ${space('m')};

  @media print {
    display: block;
    width: 100%;
  }
`;

const RelativeLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 0 0 ${color('primary')};
  background: ${color('neutral', 10)};
  padding: ${space('s')};
  transition: all 0.2s ease-in-out;
  text-align: left;

  @media print {
    display: block;
    width: 100%;
    text-decoration: none;
    padding: 0;

    svg  {
      display: none;
    }
  }

  &:hover,
  &:focus {
    transform: scale(1.01);
    z-index: 1;
    box-shadow: 0 0 5px 2px ${color('primary', 20)};
  }
`;

const Relatives = ({ items, onRouteChange }: RelativesProps) => {
  const { t } = useTranslation('common');

  return (
    <Stack gap="m">
      <Text variant="headline-4" as="h3" id="relatives">
        {t('biography.relatives')}
      </Text>

      <RelativesWrapper>
        {items.map(i => {
          if (!(i && i.slug)) return null;

          if (onRouteChange) {
            return (
              <RelativeLink
                key={i.id}
                as="button"
                type="button"
                onClick={() => onRouteChange(i.slug)}
              >
                <div>
                  <Text variant="copy-large" weight="medium">
                    {i.name}
                  </Text>
                  <Text>{t(i.type) || t('unknown')}</Text>
                </div>

                <Icon icon="Forward" />
              </RelativeLink>
            );
          }

          return (
            <Link key={i.id} href={`/biography/${i.slug}`} passHref legacyBehavior>
              <RelativeLink>
                <div>
                  <Text variant="copy-large" weight="medium">
                    {i.name}
                  </Text>
                  <Text>{t(i.type) || t('unknown')}</Text>
                </div>

                <Icon icon="Forward" />
              </RelativeLink>
            </Link>
          );
        })}
      </RelativesWrapper>
    </Stack>
  );
};

export default Relatives;
