import React from 'react';
import styled from 'styled-components';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import Icon from '../Icon';

import { color, space } from '../../stylesheets';

export const Accordion = AccordionPrimitive.Root;

export const AccordionItem = styled(AccordionPrimitive.Item as any)`
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  color: ${color('primary')};
  margin-bottom: ${space('s')};

  &:hover,
  &:focus,
  &:focus-within {
    z-index: 1;
  }
`;

const StyledHeader = styled(AccordionPrimitive.Header as any)`
  all: unset;
  display: flex;
`;

const StyledTrigger = styled(AccordionPrimitive.Trigger as any)`
  all: unset;
  background-color: transparent;
  padding: 0;
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;

  svg {
    transition: transform 0.3s ease-in-out;
    margin-right: ${space('s')};
  }

  &[data-state='open'] svg {
    transform: scaleY(-1);
  }
`;

const StyledContent = styled(AccordionPrimitive.Content as any)`
  overflow: hidden;
  color: ${color('primary')};
  padding: ${space('s')} 0;
  height: 0;

  &[data-state='open'] {
    height: 100%;
  }
`;

export const AccordionTrigger = React.forwardRef<AccordionPrimitive.AccordionHeaderProps, any>(
  ({ children, ...props }, forwardedRef) => (
    <StyledHeader>
      <StyledTrigger {...props} ref={forwardedRef}>
        <Icon icon="Expand" size="supertiny" />
        {children}
      </StyledTrigger>
    </StyledHeader>
  )
);

AccordionTrigger.displayName = 'AccordionTrigger';

export const AccordionContent = React.forwardRef<AccordionPrimitive.AccordionContentProps, any>(
  ({ children, ...props }, forwardedRef) => (
    <StyledContent {...props} ref={forwardedRef}>
      {children}
    </StyledContent>
  )
);

AccordionContent.displayName = 'AccordionContent';
