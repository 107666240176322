import { useKeenSlider } from 'keen-slider/react';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { Annotation } from '../../../types/api';

import Text from '../../../components/Text';

import { default as AnnotationItem } from './Annotation';

import { space } from '../../../stylesheets';

const StyledAnnotationList = styled.div<{ $hasAnnotations: boolean }>`
  position: relative;
  overflow: hidden;
  margin-bottom: ${space('xxl')};

  @media print {
    display: ${props => (props.$hasAnnotations ? 'block' : 'none')};
    break-inside: avoid;
  }

  & > div {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: 0 ${space('m')};
  }

  h3 {
    margin-bottom: ${space('m')};
  }

  .keen-slider {
    overflow: visible;

    @media print {
      display: block;
    }
  }

  .keen-slider__slide {
    padding-right: ${space('m')};
    justify-self: stretch;
    width: 450px;

    @media print {
      width: 100%;
    }

    &:last-of-type {
      @media print {
        display: none;
      }
    }
  }
`;

type AnnotationListProps = {
  items?: Annotation[] | null;
  slug: string;
  name: string;
};

const AnnotationList = ({ items, name, slug }: AnnotationListProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const [ref] = useKeenSlider({
    mode: 'snap',
    slides: {
      perView: 1
    },
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 1.65 }
      }
    }
  });

  const handleFeedback = (content: string) => {
    const s = encodeURIComponent(t('feedback.subject', { name }));
    const b = encodeURIComponent(t('feedback.body', { content }));
    window.location.href = `mailto:info@shoah-memorial-frankfurt.de?subject=${s}&body=${b}`;
  };

  return (
    <StyledAnnotationList $hasAnnotations={(items || []).length > 0}>
      <div>
        <Text variant="headline-4" as="h3" id="annotations">
          {t('biography.annotations')}
        </Text>

        <div ref={ref} className="keen-slider">
          {items?.map(i => (
            <div className="keen-slider__slide" key={i.createdAt}>
              <AnnotationItem
                content={i.content}
                name={i.name}
                publishedAt={i.publishedAt}
                createdBy={i.createdBy}
                verifiedAt={i.verifiedAt}
                onFeedback={() => handleFeedback(i.content)}
              />
            </div>
          ))}

          <div className="keen-slider__slide">
            <AnnotationItem
              content={t('contribution.annotation.label', { name })}
              ctaLabel={t('labels.contribute')}
              onClick={() => router.push(`/biography/${slug}/contribute/annotation`)}
            />
          </div>
        </div>
      </div>
    </StyledAnnotationList>
  );
};

export default AnnotationList;
