import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

import Text from '../../../components/Text';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../../../components/Accordion';

import { space } from '../../../stylesheets';

type GlossaryProps = {
  items: { id: string; title: string; content: string }[];
};

const Wrapper = styled.div`
  margin: ${space('l')} 0 0;

  & > * + * {
    margin-top: ${space('s')};
  }
`;

const Glossary = ({ items }: GlossaryProps) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper id="glossary">
      <Text variant="headline-4" as="h3" id="glossary">
        {t('biography.glossary')}
      </Text>

      <Accordion type="multiple">
        {items.map(item => (
          <AccordionItem key={item.id} id={`glossary-${item.title}`} value={item.title}>
            <AccordionTrigger>
              <Text weight="medium" variant="copy-small">
                {item.title}
              </Text>
            </AccordionTrigger>

            <AccordionContent>
              <Text variant="copy-small">{item.content}</Text>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </Wrapper>
  );
};

export default Glossary;
