import { useKeenSlider } from 'keen-slider/react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { getImagePath } from '../../../lib/api';
import { color, mq, space } from '../../../stylesheets';
import { Asset } from '../../../types/api';

const StyledGallery = styled.div<{ $hasImages: boolean | null }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: ${space('l')};

  @media print {
    display: ${props => (props.$hasImages ? 'block' : 'none')};
    break-inside: avoid;
  }

  &:after {
    content: '';
    position: absolute;
    background: ${color('neutral', 10)};
    height: 80%;
    width: 100%;
    max-width: calc(800px - 2 * ${space('m')});
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  .keen-slider {
    max-height: 800px;

    @media print {
      display: block;
    }
  }

  .keen-slider__slide {
    z-index: 1;
    padding: 0 ${space('m')};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      z-index: 1;

      @media print {
        display: block;
        width: 240px;
        break-inside: avoid;
      }
    }
  }
`;

const ContributionPlaceholder = styled.div`
  z-index: 1;
  width: 300px;
  height: 350px;
  color: ${color('primary')};
  background: ${color('base')};
  padding: ${space('m')};
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  @media print {
    display: none;
  }

  img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    margin: 0;
    filter: blur(12px) brightness(1.3);
    opacity: 0.3;
  }

  ${mq('m')} {
    width: 340px;
    height: 400px;
  }

  & > * + * {
    margin-top: ${space('s')};

    ${mq('m')} {
      margin-top: ${space('l')};
    }
  }

  svg {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-bottom: ${space('m')};

    ${mq('m')} {
      width: 40px;
      height: 40px;
    }
  }
`;

const PrintCaption = styled.div`
  width: 100%;
  display: none;
  padding-left: ${space('m')};

  @media print {
    display: block;
  }
`;

const Caption = styled.div`
  max-width: 650px;
  margin: ${space('s')} auto 0;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media print {
    display: none;
  }

  & > * + * {
    margin-top: ${space('xs')};
  }
`;

const Controls = styled.div`
  max-width: 800px;
  margin: ${space('s')} auto 0;
  z-index: 1;
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }
`;

type GalleryProps = {
  images?: Asset[] | null;
  name: string;
  slug: string;
};

const Gallery = ({ images = [], name, slug }: GalleryProps) => {
  const router = useRouter();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation('common');

  const [ref, instanceRef] = useKeenSlider({
    mode: 'snap',
    slides: {
      origin: 'center',
      perView: 1,
      spacing: 0
    },
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 2, origin: 'center', spacing: 0 }
      }
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    }
  });

  const handlePrev = () => {
    instanceRef.current?.prev();
  };

  const handleNext = () => {
    instanceRef.current?.next();
  };

  const hasImages = images && images.length > 0;
  const maxImages = (images?.length || 0) + 1;

  return (
    <StyledGallery id="assets" $hasImages={hasImages}>
      <div ref={ref} className="keen-slider">
        {images?.map(i => (
          <div className="keen-slider__slide" key={i.url}>
            <Image
              src={getImagePath(i.url)}
              alt={i.caption as string}
              width={parseInt((i.width || '400') as string, 10)}
              height={parseInt((i.height || '400') as string, 10)}
            />

            <PrintCaption>
              {i?.caption && <Text variant="hint">{i.caption}</Text>}
              {i?.credits && <Text variant="hint">{i.credits}</Text>}

              {i?.createdBy && (
                <Text variant="hint">
                  {t('createdBy')} {i?.name || t(i.createdBy as string)}
                </Text>
              )}
            </PrintCaption>
          </div>
        ))}

        <div className="keen-slider__slide">
          <ContributionPlaceholder>
            <Text variant="copy-large">{t('contribution.asset.label', { name })}</Text>

            <Button
              label={t('labels.contribute')}
              variant="secondary"
              onClick={() => router.push(`/biography/${slug}/contribute/asset`)}
            />

            <Image src="/images/placeholder.jpg" alt="" width={218} height={266} />
          </ContributionPlaceholder>
        </div>
      </div>

      {hasImages && (
        <Caption>
          {images[currentSlide]?.caption && (
            <Text variant="hint">{images[currentSlide].caption}</Text>
          )}

          {images[currentSlide]?.credits && (
            <Text variant="hint">{images[currentSlide].credits}</Text>
          )}

          {images[currentSlide]?.createdBy && (
            <Text variant="hint">
              {t('createdBy')}{' '}
              {images[currentSlide]?.name || t(images[currentSlide].createdBy as string)}
            </Text>
          )}
        </Caption>
      )}

      {hasImages && (
        <Controls>
          <Button
            onClick={handlePrev}
            icon="Backward"
            label="Previous"
            variant="tertiary"
            iconPosition="only"
            disabled={currentSlide === 0}
          />

          <Text>
            {currentSlide + 1}/{maxImages}
          </Text>

          <Button
            onClick={handleNext}
            icon="Forward"
            label="Next"
            variant="tertiary"
            iconPosition="only"
            disabled={currentSlide + 1 === maxImages}
          />
        </Controls>
      )}
    </StyledGallery>
  );
};

export default Gallery;
