import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import styled from 'styled-components';
import Text from '../../../components/Text';

import { space, color, mq } from '../../../stylesheets';

export const StyledContributeLink = styled.div`
  position: sticky;
  bottom: ${space('m')};
  right: 0;
  margin: ${space('m')} 0 0;
  display: flex;
  justify-content: center;
  z-index: 990;

  ${mq('m')} {
    justify-content: flex-end;
    bottom: ${space('xl')};
    margin: 0 ${space('xl')};
  }

  @media print {
    display: none;
  }

  a {
    text-align: center;
    background: ${color('neutral', 10)};
    box-shadow: 0 0 5px 2px ${color('primary', 20)};
    border-radius: 20% 18% 21% 39% / 64% 56% 44% 36%;
    padding: ${space('s')};
    margin-top: ${space('m')};

    ${mq('m')} {
      width: auto;
      padding: ${space('m')};
      margin-top: 0;
    }
  }

  svg {
    width: 30px;
    height: 30px;
    stroke-width: 5px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    stroke-linecap: round;
    margin-right: ${space('s')};
    animation: dash 3s 0.4s linear forwards;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;
type ContributeLinkProps = { slug: string };

const ContributeLink = ({ slug }: ContributeLinkProps) => {
  const { t } = useTranslation('common');

  return (
    <StyledContributeLink>
      <Link href={`/biography/${slug}/contribute`} passHref legacyBehavior>
        <a>
          <svg width="84" height="84" viewBox="0 0 84 84" xmlns="http://www.w3.org/2000/svg">
            <g
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              fillRule="evenodd"
            >
              <path d="M15.995 68.336 53.558 82 40.076 42 4.364 43.182z" />
              <path d="M47.643 2 80 23.303l-9.69 45.613L53.558 82 40.076 42z" />
              <path d="M4.364 43.182 47.643 2l-7.567 40z" />
            </g>
          </svg>

          <Text variant="button" transform="uppercase" weight="medium" as="span">
            {t('labels.contribute')}
          </Text>
        </a>
      </Link>
    </StyledContributeLink>
  );
};

export default ContributeLink;
