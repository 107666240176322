import Head from 'next/head';

type SEOProps = {
  canonical?: string;
  title?: string;
  image?: string;
  description?: string;
};

const SEO = ({ canonical, title, description, image = '/seo-image.jpg' }: SEOProps) => {
  const getTitle = () => {
    if (!title) return 'Shoah Memorial Frankfurt';
    return `${title} – Shoah Memorial Frankfurt`;
  };
  return (
    <Head>
      {canonical && (
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_BASE_URL}${canonical}`} />
      )}

      <title>{getTitle()}</title>
      <meta property="og:title" content={getTitle()} />
      {image && <meta property="og:image" content={image} />}
      <meta name="twitter:card" content="summary_large_image" />

      {description && (
        <>
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
        </>
      )}
    </Head>
  );
};

export default SEO;
