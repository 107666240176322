export const getRandomNumber = (min: number, max: number) => Math.random() * (max - min) + min;
export const getRandomRotation = (min: number, max: number) => -Math.PI / getRandomNumber(min, max);

export const getNumberByPercentage = (min: number, max: number, percentage: number) => percentage * (max - min) + min;

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const getRandomItemFromArray = <T>(array: T[]) =>
  array[Math.floor(Math.random() * array.length)];

export const getRandomValueFromObject = (obj: any) => {
  const keys = Object.keys(obj);
  const key = keys[Math.floor(Math.random() * keys.length)];
  return obj[key];
};

export const chunkArray = <T>(arr: T[], chunkSize: number) => {
  const res = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }

  return res;
};

export const shuffleArray = <T>(array: T[]) => array.sort(() => 0.5 - Math.random());

export const decodeBase64 = (data: string) => {
  const buff = new Buffer(data, 'base64');
  const text = buff.toString('ascii');
  return JSON.parse(text);
};
