import dayjs from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Richtext from '../../../components/Richtext';
import Text from '../../../components/Text';

import { color, mq, space } from '../../../stylesheets';

export type AnnotationProps = {
  onClick?: () => void;
  onFeedback?: () => void;
  publishedAt?: string | null;
  createdBy?: 'editor' | 'user';
  verifiedAt?: string | null;
  content: string;
  name?: string;
  ctaLabel?: string;
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  background: ${color('neutral', 10)};
  padding: ${space('m')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100px;

  @media print {
    display: block;
    break-inside: avoid;
    padding: 0;
    height: auto;
    padding-left: ${space('m')};
    min-height: none;
    border-left: 1px solid currentColor;
    margin-bottom: ${space('l')};
  }

  & > * + * {
    margin-top: ${space('xl')};

    @media print {
      margin-top: ${space('s')};
    }
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 2;
`;

const Meta = styled.div`
  button {
    margin: 0;
    padding: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  & > div > * + * {
    margin-left: ${space('xxs')} !important;
  }
`;

const UnverifiedLabel = styled.div`
  display: inline-flex;
  align-items: flex-start;
  padding-bottom: ${space('s')};

  span {
    hyphens: auto;
    line-height: 1.5;
  }

  svg {
    flex-shrink: 0;
    margin-right: ${space('xxs')};

    ${mq('m')}  {
      margin-top: 2px;
    }
  }
`;

const Annotation = ({
  onClick,
  onFeedback,
  content,
  name,
  verifiedAt,
  publishedAt,
  createdBy = 'editor',
  ctaLabel
}: AnnotationProps) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <ContentWrapper>
        {publishedAt && !verifiedAt && (
          <UnverifiedLabel>
            <Icon icon="Info" size="supertiny" />

            <Text as="span" variant="hint" weight="medium">
              {t('notVerified')}
            </Text>
          </UnverifiedLabel>
        )}

        <Richtext content={content} />
      </ContentWrapper>

      {createdBy && publishedAt && (
        <Meta>
          <Text variant="hint" as="span" weight="medium">
            {t('createdBy')} {name || t(createdBy)}
          </Text>

          <div>
            <Text variant="hint" as="span">
              {t(verifiedAt ? 'verifiedAt' : 'publishedAt')}{' '}
              {dayjs(verifiedAt || publishedAt).format(t('dateFormat.short'))}
            </Text>

            {onFeedback ? (
              <>
                <Text variant="hint" as="span">
                  |
                </Text>

                <button onClick={onFeedback} type="button">
                  <Text variant="hint" as="span">
                    {t('sendFeedback')}
                  </Text>
                </button>
              </>
            ) : null}
          </div>
        </Meta>
      )}

      {onClick && ctaLabel && <Button label={ctaLabel} variant="secondary" onClick={onClick} />}
    </Wrapper>
  );
};

export default Annotation;
