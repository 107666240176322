import React, { useCallback, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

import { Biography, GlossaryItem } from '../../types/api';

import Text from '../../components/Text';

import Badge from './components/Badge';
import Sources from './components/Sources';
import Details from './components/Details';
import Relatives from './components/Relatives';
import Glossary from './components/Glossary';
import Gallery from './components/Gallery';
import AnnotationList from './components/AnnotationList';
import ContributeLink from './components/ContributeLink';
import Stack from '../../components/Stack';

import {
  ContentWrapper,
  BiographyText,
  StyledBiography,
  LocalizationHint
} from './Biography.style';
import Icon from '../../components/Icon';

type BiographyProps = Biography & {
  glossary?: GlossaryItem[];
  onRouteChange?: (slug: string) => void;
};

const Biography = (props: BiographyProps) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const {
    name,
    slug,
    birthDate,
    birthDatePartial,
    birthName,
    lastNameCorrected,
    firstNameCorrected,
    birthNameCorrected,
    deathDate,
    deathDatePartial,
    deportationDate,
    lastAddress,
    placeOfBirth,
    placeOfDeath,
    deportedTo,
    biography,
    addresses,
    relatives,
    informationDensity,
    schools,
    onlineSources,
    sources,
    assets,
    annotations,
    updatedOn,
    glossary = [],
    onRouteChange
  } = props;

  const getAllAddresses = useCallback(() => {
    const allAddresses = lastAddress ? [...(addresses || []), lastAddress] : addresses;
    return allAddresses?.filter((element, index) => allAddresses.indexOf(element) !== index);
  }, [lastAddress, addresses]);

  const allAddresses = getAllAddresses();

  const { replacedBiography, glossaryItems } = useMemo(() => {
    let bio = biography;
    const glossaryItems: GlossaryItem[] = [];

    if (glossary) {
      glossary.forEach(i => {
        if (!bio?.includes(i.title)) return;
        glossaryItems.push(i);
        bio = bio?.replaceAll(i.title, `<a href="#glossary">${i.title}</a>`);
      });
    }

    return {
      replacedBiography: bio?.replaceAll('\n', '<br/>'),
      glossaryItems
    };
  }, [glossary, biography]);

  return (
    <StyledBiography>
      <Badge
        name={name}
        birthName={birthNameCorrected || birthName}
        informationDensity={informationDensity}
        birthDate={birthDate}
        birthDatePartial={birthDatePartial}
        deathDate={deathDate}
        deathDatePartial={deathDatePartial}
      />

      <Details
        slug={slug}
        birthDate={birthDate}
        birthDatePartial={birthDatePartial}
        placeOfBirth={placeOfBirth}
        deathDate={deathDate}
        deathDatePartial={deathDatePartial}
        placeOfDeath={placeOfDeath}
        deportationDate={deportationDate}
        deportedTo={deportedTo}
        addresses={addresses}
        schools={schools}
        lastAddress={lastAddress}
        firstNameCorrected={firstNameCorrected?.replaceAll(';', ',')}
        lastNameCorrected={lastNameCorrected}
      />

      <Gallery images={assets} name={name} slug={slug} />

      <ContentWrapper>
        <Stack gap="m">
          <Text variant="headline-4" as="h3" id="biography">
            {t('biography.biography')}
          </Text>

          {locale === 'en' && (
            <LocalizationHint>
              <Icon icon="Info" size="tiny" />

              <Text>
                The Shoah Memorial Frankfurt is being updated on an ongoing basis. If this biography
                is not translated yet, please use your browser&apos;s automatic translation for now.
              </Text>
            </LocalizationHint>
          )}

          {replacedBiography && (
            <BiographyText
              variant="copy-large"
              dangerouslySetInnerHTML={{
                __html: replacedBiography
              }}
            />
          )}
        </Stack>

        {relatives && relatives.length > 0 && (
          <Relatives items={relatives} onRouteChange={onRouteChange} />
        )}
      </ContentWrapper>

      <AnnotationList items={annotations} slug={slug} name={name} />

      <ContentWrapper>
        <Sources onlineSources={onlineSources} sources={sources} />

        {glossaryItems?.length > 0 && <Glossary items={glossaryItems} />}

        <Text variant="copy-small">
          {t('updatedOn')}: {dayjs(updatedOn).format(t('dateFormat.full'))}
        </Text>
      </ContentWrapper>

      <ContributeLink slug={slug} />
    </StyledBiography>
  );
};

export default Biography;
