import dayjs from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../../components/Icon';
import Stack from '../../../components/Stack';

import Text from '../../../components/Text';
import { btnReset, color, mq, space } from '../../../stylesheets';
import { Biography } from '../../../types/api';

type DetailsProps = Pick<
  Biography,
  | 'birthDate'
  | 'birthDatePartial'
  | 'slug'
  | 'placeOfBirth'
  | 'placeOfDeath'
  | 'deportationDate'
  | 'deportedTo'
  | 'deathDate'
  | 'deathDatePartial'
  | 'addresses'
  | 'lastAddress'
  | 'schools'
  | 'lastNameCorrected'
  | 'firstNameCorrected'
>;

const Entry = styled.div`
  display: grid;
  grid-gap: ${space('xs')};
  grid-template-columns: 1fr 1fr;
  padding-right: ${space('l')};
`;

const List = styled.div`
  display: grid;
  grid-row-gap: ${space('m')};
  grid-template-columns: 1fr;
  align-items: flex-start;

  ${mq('m')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Wrapper = styled(Stack)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${space('m')};
  color: ${color('primary')};
`;

const ContributeLinkWrapper = styled.a`
  ${btnReset}
  text-align: left;
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }

  svg {
    margin-right: ${space('xxs')};
  }
`;

type ContributeLinkProps = { slug: string };
const ContributeLink = ({ slug }: ContributeLinkProps) => {
  const { t } = useTranslation('common');

  return (
    <Link href={`/biography/${slug}/contribute/annotation`} passHref legacyBehavior>
      <ContributeLinkWrapper>
        <Icon icon="Edit" size="supertiny" />

        <Text variant="hint" as="span" transform="uppercase">
          {t('labels.contributeShort')}
        </Text>
      </ContributeLinkWrapper>
    </Link>
  );
};

const Details = ({
  birthDate,
  birthDatePartial,
  placeOfBirth,
  placeOfDeath,
  deportationDate,
  addresses,
  lastAddress,
  deathDate,
  deathDatePartial,
  firstNameCorrected,
  lastNameCorrected,
  slug,
  deportedTo
}: DetailsProps) => {
  const { t } = useTranslation('common');

  const getBirthDate = () => {
    if (birthDate) return dayjs(birthDate).format(t('dateFormat.short'));
    if (birthDatePartial) return birthDatePartial;
    return undefined;
  };

  const getDeathDate = () => {
    if (deathDate) return dayjs(deathDate).format(t('dateFormat.short'));
    if (deathDatePartial) return deathDatePartial;
    return undefined;
  };

  const contribute = useMemo(() => <ContributeLink slug={slug} />, [slug]);

  return (
    <Wrapper gap="m">
      <Text variant="headline-4" as="h3" id="details">
        {t('biography.details')}
      </Text>

      <List>
        {(firstNameCorrected || lastNameCorrected) && (
          <>
            <Entry>
              {firstNameCorrected && (
                <>
                  <Text transform="uppercase" weight="medium">
                    {t('biography.firstNameCorrected')}
                  </Text>

                  <Text>{firstNameCorrected}</Text>
                </>
              )}
            </Entry>

            <Entry>
              {lastNameCorrected && (
                <>
                  <Text transform="uppercase" weight="medium">
                    {t('biography.lastNameCorrected')}
                  </Text>

                  <Text>{lastNameCorrected}</Text>
                </>
              )}
            </Entry>
          </>
        )}

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.birthDate')}
          </Text>

          {getBirthDate() ? <Text>{getBirthDate()}</Text> : contribute}
        </Entry>

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.placeOfBirth')}
          </Text>

          {placeOfBirth ? <Text>{placeOfBirth}</Text> : contribute}
        </Entry>

        {/* <Entry>
          <Text  transform="uppercase" weight="medium">
            {t('biography.schools')}
          </Text>

          {(schools || []).length > 0 ? <Text>{schools?.join(', ')}</Text> : contribute}
        </Entry> */}

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.lastAddress')}
          </Text>
          {lastAddress ? <Text>{lastAddress}</Text> : contribute}
        </Entry>

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.address')}
          </Text>

          {(addresses || []).length > 0 ? <Text>{addresses?.join(', ')}</Text> : contribute}
        </Entry>

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.deportationDate')}
          </Text>

          {deportationDate ? (
            <Text>{dayjs(deportationDate).format(t('dateFormat.short'))}</Text>
          ) : (
            contribute
          )}
        </Entry>

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.deportedTo')}
          </Text>

          {(deportedTo || []).length > 0 ? <Text>{deportedTo?.join(', ')}</Text> : contribute}
        </Entry>

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.deathDate')}
          </Text>

          {getDeathDate() ? <Text>{getDeathDate()}</Text> : contribute}
        </Entry>

        <Entry>
          <Text transform="uppercase" weight="medium">
            {t('biography.placeOfDeath')}
          </Text>

          {placeOfDeath ? <Text>{placeOfDeath}</Text> : contribute}
        </Entry>
      </List>
    </Wrapper>
  );
};

export default Details;
