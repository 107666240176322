import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Footer from '../Footer';
import Header from '../Header';

import { color, space, variant } from '../../stylesheets';

const Wrapper = styled.div`
  position: relative;
  background: transparent;
`;

const Container = styled.div<{
  $size: 'narrow' | 'full';
  $background: 'neutral' | 'base';
  $shade: number;
}>`
  position: relative;
  min-height: calc(100vh - 2 * ${space('xxl')});
  background: ${props => color(props.$background, props.$shade)};
  z-index: 2;

  ${variant({
    prop: '$size',
    narrow: css`
      max-width: 700px;
      margin: 0 auto;
    `
  })}
`;

type LayoutProps = {
  children: ReactNode;
  background?: 'neutral' | 'base';
  size?: 'narrow' | 'full';
  hideFooter?: boolean;
};

const Layout = ({ children, background = 'neutral', size = 'full', hideFooter }: LayoutProps) => (
  <Wrapper>
    <Container $size={size} $background={background} $shade={background === 'neutral' ? 10 : 50}>
      <Header />
      {children}
    </Container>

    {!hideFooter && <Footer />}
  </Wrapper>
);

export default Layout;
